import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import Text from "../components/atoms/Text/Text";

// type propType = {
//     data: any
//     classes: any,
//     context: any
// }

const ImpressPage = () => {
    const {t} = useTranslation();

    return (
        <>
            <FullWidthContainer>
                <h1>{t('impress:mainTitle')}</h1>
            </FullWidthContainer>
            <FullWidthContainer>
                <h2 className="margin-top-0">{t('impress:sectionEditor.headline')}</h2>
                <p><Text text={t('impress:sectionEditor.copy')} /></p>
            </FullWidthContainer>
            <FullWidthContainer>
                <p><Text text={t('impress:copy')} /></p>
            </FullWidthContainer>
        </>
    )
}

export default ImpressPage;

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "impress"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
	}
`;
